exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-messages-js": () => import("./../../../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/searchResults.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-license-template-js": () => import("./../../../src/templates/license.template.js" /* webpackChunkName: "component---src-templates-license-template-js" */),
  "component---src-templates-messages-template-js": () => import("./../../../src/templates/messages.template.js" /* webpackChunkName: "component---src-templates-messages-template-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags.template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

